import {createRouter, createWebHistory} from "vue-router"
import admin from "@/router/modules/admin" // 我的
import product from "@/router/modules/product" // 分类
import cart from "@/router/modules/cart" // 分类

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: '/Login',
		},
		{
			path: '/login',
			name: 'Login', // 登录页
			component: () => import('../pages/Login.vue')
		},
		{
			path: '/home',
			name: 'Home', // 首页
			component: () => import('../pages/Home.vue')
		},
		...admin,
		...product,
		...cart,
	]
})