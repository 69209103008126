export default [
    // 我的
    {
        path: '/admin/personal',
        name: 'Personal', // 我的
        component: () => import('@/pages/admin/Personal.vue')
    },
    {
        path: '/admin/userInfo',
        name: 'UserInfo', // 我的详情
        component: () => import('@/pages/admin/UserInfo.vue')
    },
    {
        path: '/admin/order',
        name: 'Order', // 订单
        component: () => import('@/pages/admin/Order.vue')
    },
    {
        path: '/admin/orderDetail',
        name: 'OrderDetail', // 订单详情
        component: () => import('@/pages/admin/OrderDetail.vue')
    },
    {
        path: '/admin/locationPage',
        name: 'LocationPage', // 收货地址
        component: () => import('@/pages/admin/LocationPage.vue')
    },
    {
        path: '/admin/invoicePage',
        name: 'InvoicePage', // 发票信息
        component: () => import('@/pages/admin/InvoicePage.vue')
    },
]