<template>
    <router-view></router-view>
</template>
<script>
import {onMounted} from "vue";
import {useI18n} from "vue-i18n";

export default {
    setup(props, ctx) {
        // 写入打包时间
        onMounted(() => {
            document.querySelector('body').setAttribute('VITE_APP_BUILDTIME', import.meta.env.VITE_APP_BUILDTIME)
        })
        const i18n = useI18n()
        onMounted(async () => {
            i18n.locale.value = sessionStorage.getItem('i18n') || 'zh'
        })

        return {

        }
    }
}
</script>
<style lang="scss">
* {
    font-size: 14rem;
}
</style>