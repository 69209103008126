export default [
    // 分类
    {
        path: '/product/classification',
        name: 'Classification', // 产品分类
        component: () => import('@/pages/product/Classification.vue')
    },
    {
        path: '/product/productDetail',
        name: 'ProductDetail', // 产品详情
        component: () => import('@/pages/product/ProductDetail.vue')
    },
]