const en = {
	collect_cancel: 'cancel collect',
	product_addToCollect: 'add to collect',
	product_addToCart: 'add to cart',
	product_articleNumber: 'article number',
	product_standard: 'standard',
	product_price: 'price(CNY)',
	product_inventory: 'inventory',
	product_quantity: 'quantity',
	product_deliveryDays1: 'expected to ship in',
	product_deliveryDays2: 'days after ordering',
	product_detailInfo: 'Details',
	product_resource: 'Resource',
	product_sdscoa: 'SDS, COA and more',
	product_allChoose: 'select all',
	product_product: 'product',
	product_total: 'total',
	product_operating: 'operating',
	product_deliveryDays: 'Estimated shipping date after ordering',
	product_size: 'size',
	product_inputReservedNum: 'Enter the reserved order number',
	product_del: 'delete',
	product_addOthers: 'Need to add other products?',
	product_addOthersTips: 'Enter the  article number and the quantity of it. then click on the "add to cart" button.',
	product_reservedNum: 'reserved order number',
	product_inputArticleNumber: 'input article number',
	product_inputQuantity: 'input quantity',
	product_inputReservedNum2: 'input reserved order number',
	product_clean: 'clean',

	cart_title: 'Cart',
	cart_clean: 'clear cart',
	cart_summary: 'Cart Summary',
	cart_productPrice: 'product price',
	cart_totalPrice: 'total price including tax(CNY)',
	cart_tips: 'Note: Due to rounding, the price calculated by the website will be slightly different from the invoice price, please refer to the invoice price!',
	cart_settleAccounts: 'Settle Accounts',
	cart_continueShopping: 'Continue Shopping',
}
export default en