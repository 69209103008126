<template>
    <div class="footerWarp">
        <div v-for="item in tabs"
             class="tabs"
             @click="jump(item.url)">
            <i :class="[item.icon,{active: isOn === item.active}]"></i>
            <p :class="{active: isOn === item.active}">{{item.title}}</p>
        </div>
    </div>
</template>

<script>
import {reactive,toRefs} from 'vue'
import {useRouter} from 'vue-router'
import {useI18n} from "vue-i18n";
export default {
    name: 'Footer',
    props: {
        isOn:{
            type: String,
            default: '',
        }
    },
    setup() {
        const i18n = useI18n();
        const router = useRouter();
        const state = reactive({
            tabs:[
                {title: i18n.t('home_home'),icon: `iconfont icon-shouye`,active: 'home',url: '/home'},
                {title: i18n.t('footer_classification'),icon: 'iconfont icon-fenlei',active: 'classification',url: '/product/classification'},
                {title: i18n.t('header_cart'),icon: 'iconfont icon-cart',active: 'cart',url: '/cart/cart'},
                {title: i18n.t('footer_my'),icon: 'iconfont icon-wode',active: 'personal',url: '/admin/personal'},
            ]
        })
        const jump = (url) => {
            router.push(url)
        }
        return {
            ...toRefs(state),
            jump
        }
    },
}
</script>


<style lang="scss" scoped>
.footerWarp {
    width: 100vw;
    height: 60rem;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid #eee;
    .tabs {
        width: 25vw;
        text-align: center;
        padding-top: 10rem;

        .iconfont {
            font-size: 24rem;
        }

        .active {
            color: #254F9E;
        }
    }
    .qqq {
    }
}
</style>