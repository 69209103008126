export default [
    // 购物车
    {
        path: '/cart/cart',
        name: 'Cart', // 购物车
        component: () => import('@/pages/cart/Cart.vue')
    },
    {
        path: '/cart/placeOrder',
        name: 'PlaceOrder', // 结算页面
        component: () => import('@/pages/cart/PlaceOrder.vue')
    },
    {
        path: '/cart/orderOver',
        name: 'OrderOver', // 提交成功页面
        component: () => import('@/pages/cart/OrderOver.vue')
    },
]