<template>
    <div class="header">
        <el-icon v-if="isBack" class="back" @click="back"><ArrowLeftBold /></el-icon>
        <div class="title">{{headerTitle}}</div>
        <p class="i18n" @click="i18nChange">{{$t('header_Language')}}</p>
    </div>
</template>

<script>
import {ArrowLeftBold} from '@element-plus/icons-vue'
import {useI18n} from 'vue-i18n'
import {useRouter} from 'vue-router'
export default {
    name: 'Header',
    props: {
        headerTitle: {
            type:String,
            default: '',
        },
        isBack: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        ArrowLeftBold
    },
    setup() {
        const router = useRouter();
        // 中英文
        const i18n = useI18n()
        const i18nChange = () => {
            i18n.locale.value = i18n.locale.value === 'en' ? 'zh' : 'en'
            sessionStorage.setItem('i18n', i18n.locale.value)
            location.reload()
        }
        const back = () => {
            router.back();
        }
        return {
            i18nChange,
            back
        }
    },
}
</script>


<style lang="scss" scoped>
    .header {
        position: relative;
        width: 100vw;
        height: 56rem;
        background-color: #F2F2F2;
        text-align: center;
        .back {
            position: absolute;
            left: 27rem;
            top: 22rem;
            transform: scale(1.2);
        }
        .i18n {
            position: absolute;
            right: 27rem;
            top: 22rem;
            transform: scale(1.2);
        }

        .title {
            line-height: 56rem;
            font-weight: bold;
            font-size: 18rem;
        }
    }
</style>