const zh = {
	collect_cancel: '取消收藏',
	product_addToCollect: '添加至收藏夹',
	product_addToCart: '添加至购物车',
	product_articleNumber: '货号',
	product_standard: '规格',
	product_price: '价格（CNY）',
	product_inventory: '库存状况',
	product_quantity: '数量',
	product_deliveryDays1: '下单后预计',
	product_deliveryDays2: '天后发货',
	product_detailInfo: '详细信息',
	product_resource: '资源',
	product_sdscoa: 'SDS、COA及更多',
	product_allChoose: '全选',
	product_product: '产品',
	product_total: '合计',
	product_operating: '操作',
	product_deliveryDays: '下单后预计发货日期',
	product_size: '产品尺寸',
	product_inputReservedNum: '输入预留单号',
	product_del: '删除',
	product_addOthers: '需要添加其他产品？',
	product_addOthersTips: '输入货号和数量，将产品添加至购物车中',
	product_reservedNum: '预留单号',
	product_inputArticleNumber: '请输入需要添加的货号',
	product_inputQuantity: '请输入数量',
	product_inputReservedNum2: '请输入预留单号',
	product_clean: '清除',

	cart_title: '购物车',
	cart_clean: '清空购物车',
	cart_summary: '购物车摘要',
	cart_productPrice: '产品价格',
	cart_totalPrice: '含税总价（CNY）',
	cart_tips: '注：由于四舍五入，网站计算价格会与发票价格有细微差别，请以发票价格为准！',
	cart_settleAccounts: '去结算',
	cart_continueShopping: '继续购物',
}
export default zh